<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="reasonCategory"
          :items="selectReason"
          background-color="#464646"
          class="rounded-lg"
          clearable
          flat
          hide-details="auto"
          item-text="text"
          item-value="key"
          label="Список пользователей, попавших под подозрение по критерию"
          solo
        >
          <template v-slot:prepend-inner>
            <v-icon class="mr-1">
              mdi-account-cancel
            </v-icon>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <DatePicker
          label="Дата создания (выбрать промежуток)"
          @save="saveDates"
        />
      </v-col>

      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/getAntifrodUsers.gql')"
          :variables="{
            createdFrom,
            createdTo,
            pageIndex: page,
            pageSize: itemsPerPage,
            reason: reasonCategory || null,
          }"
        >
          <template v-slot="{ result: { error: antifroadError, data: antifroadData }, isLoading: antifroadIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="antifroadError"
              :message="antifroadError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="antifroadData || antifroadIsLoading === 1"
              :loading="antifroadIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="antifroadData">
                <v-card-title class="py-7">
                  Пользователи: {{ antifroadData.getAntifrodUsers.total }}
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="antifroadData.getAntifrodUsers.users"
                  :items-per-page="itemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:top>
                    <ApolloMutation
                      :mutation="require('../graphql/mutations/removeUserFromAntifrod.gql')"
                      :refetch-queries="refetchQueriesAfterMyMutation"
                      :variables="{
                        userId,
                        reason,
                      }"
                    >
                      <template v-slot="{ mutate, loading, error: removeError }">
                        <v-dialog
                          v-model="dialog"
                          max-width="400px"
                          persistent
                        >
                          <v-card :loading="loading">
                            <v-card-title>Одобрить пользователя</v-card-title>

                            <v-card-text>
                              <v-select
                                v-model="reason"
                                :items="reasons.map(item => item.text)"
                                clearable
                                hide-details="auto"
                                label="Причина"
                              />
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="primary"
                                text
                                @click="close"
                              >
                                Отмена
                              </v-btn>
                              <v-btn
                                color="error"
                                text
                                @click="mutate()"
                              >
                                Одобрить
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <AppNotification
                          v-if="removeError"
                          :icon-disabled="true"
                          :message="removeError.message"
                        />
                      </template>
                    </ApolloMutation>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.reasons`]="{ item }">
                    <v-chip
                      v-if="formartReasons(item)[0]"
                      color="error"
                    >
                      <span>{{ formartReasons(item)[0].text }}</span>
                    </v-chip>
                    <span
                      v-if="formartReasons(item)[1]"
                      class="grey--text text-caption"
                    >
                      (+{{ formartReasons(item).length - 1 }} других)
                    </span>
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      class="text-none"
                      color="primary"
                      depressed
                      height="36"
                      large
                      @click.stop="removeUserFromAntifraud(item)"
                    >
                      Одобрить
                    </v-btn>
                  </template>
                </v-data-table>
                <v-divider />

                <div class="text-center pt-3">
                  <v-pagination
                    v-model="page"
                    :length="antifroadData.getAntifrodUsers.pages"
                    :total-visible="9"
                  />

                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="firstPage"
                  />
                </div>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';
import AppNotification from '@/components/AppNotification.vue';
import getAntifrodUsers from '@/graphql/queries/getAntifrodUsers.gql';

export default {
  components: {
    AppRow,
    DatePicker,
    AppNotification,
  },
  data() {
    return {
      reasonCategory: '',
      selectReason: [
        { text: 'Совпадают кошельки депозитов на 2-х и более аккаунтах', key: 'DEPOSIT_WALLET' },
        { text: 'Совпадают кошельки выводов на 2-х и более аккаунтах', key: 'WITHDRAW_WALLET' },
        { text: 'Совпадает ip-адрес на 2-х и более аккаунтах', key: 'IP' },
        { text: 'Совпадают регистрационные данные на 2-х и более аккаунтах', key: 'INFO' },
        { text: 'Совпадающий FingerPrint (отпечаток железа)', key: 'DEVICE' },
      ],
      createdFrom: '',
      createdTo: '',
      reasonsTemplate: [
        {
          text: 'DEPOSIT_WALLET',
          value: 'duplicatedDepositWallet',
        },
        {
          text: 'WITHDRAW_WALLET',
          value: 'duplicatedWithdrawWallet',
        },
        {
          text: 'IP',
          value: 'duplicatedIp',
        },
        {
          text: 'INFO',
          value: 'duplicatedInfo',
        },
        {
          text: 'DEVICE',
          value: 'duplicatedDevice',
        },
      ],
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Ник:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Баланс (real):', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Дата регистрации:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Депозитов:', value: 'depositCount', class: 'white--text text-subtitle-2' },
        { text: 'Выводов:', value: 'withdrawCount', class: 'white--text text-subtitle-2' },
        { text: 'Причины:', value: 'reasons', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      dialog: false,
      userId: '',
      reasons: [],
      reason: '',
      page: 0,
      itemsPerPage: 5,
    };
  },
  methods: {
    firstPage() {
      this.page = 0;
    },
    saveDates(dates) {
      [this.createdFrom, this.createdTo] = dates;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.id = '';
        this.reasons = [];
      });
    },
    refetchQueriesAfterMyMutation() {
      this.close();

      return [{
        query: getAntifrodUsers,
        variables: {
          createdFrom: this.createdFrom,
          createdTo: this.createdTo,
          pageIndex: this.page,
          pageSize: this.itemsPerPage,
          reason: this.reasonCategory || null,
        },
      }];
    },
    removeUserFromAntifraud(item) {
      this.dialog = true;
      this.userId = +item.id;
      this.reasons = this.formartReasons(item);
    },
    formartReasons(item) {
      return this.reasonsTemplate.filter(reason => item[reason.value]);
    },
  },
};
</script>
