var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"only-owner":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"items":_vm.selectReason,"background-color":"#464646","clearable":"","flat":"","hide-details":"auto","item-text":"text","item-value":"key","label":"Список пользователей, попавших под подозрение по критерию","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-account-cancel ")])]},proxy:true}]),model:{value:(_vm.reasonCategory),callback:function ($$v) {_vm.reasonCategory=$$v},expression:"reasonCategory"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('DatePicker',{attrs:{"label":"Дата создания (выбрать промежуток)"},on:{"save":_vm.saveDates}})],1),_c('v-col',[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getAntifrodUsers.gql'),"variables":{
          createdFrom: _vm.createdFrom,
          createdTo: _vm.createdTo,
          pageIndex: _vm.page,
          pageSize: _vm.itemsPerPage,
          reason: _vm.reasonCategory || null,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ref_result = ref.result;
        var antifroadError = ref_result.error;
        var antifroadData = ref_result.data;
        var antifroadIsLoading = ref.isLoading;
return [(antifroadError)?_c('AppNotification',{attrs:{"message":antifroadError.message}}):(antifroadData || antifroadIsLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":antifroadIsLoading === 1,"flat":""}},[(antifroadData)?[_c('v-card-title',{staticClass:"py-7"},[_vm._v(" Пользователи: "+_vm._s(antifroadData.getAntifrodUsers.total)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":antifroadData.getAntifrodUsers.users,"items-per-page":_vm.itemsPerPage,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/removeUserFromAntifrod.gql'),"refetch-queries":_vm.refetchQueriesAfterMyMutation,"variables":{
                      userId: _vm.userId,
                      reason: _vm.reason,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var mutate = ref.mutate;
                    var loading = ref.loading;
                    var removeError = ref.error;
return [_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":loading}},[_c('v-card-title',[_vm._v("Одобрить пользователя")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.reasons.map(function (item) { return item.text; }),"clearable":"","hide-details":"auto","label":"Причина"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Одобрить ")])],1)],1)],1),(removeError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":removeError.message}}):_vm._e()]}}],null,true)})]},proxy:true},{key:"item.createdAt",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.reasons",fn:function(ref){
                    var item = ref.item;
return [(_vm.formartReasons(item)[0])?_c('v-chip',{attrs:{"color":"error"}},[_c('span',[_vm._v(_vm._s(_vm.formartReasons(item)[0].text))])]):_vm._e(),(_vm.formartReasons(item)[1])?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.formartReasons(item).length - 1)+" других) ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","depressed":"","height":"36","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeUserFromAntifraud(item)}}},[_vm._v(" Одобрить ")])]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"text-center pt-3"},[_c('v-pagination',{attrs:{"length":antifroadData.getAntifrodUsers.pages,"total-visible":9},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-select',{staticClass:"pa-4 pt-5",attrs:{"items":[5, 10, 15],"label":"Строк на странице"},on:{"change":_vm.firstPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)]:_vm._e()],2):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }